import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useWindowSize } from 'react-use'
import { ProductSlider } from '../components/shared/sliders'

import {
    HeroBlock,
    RichText,
    Social,
    Productcard,
    SEO,
} from '../components/shared'

import {
    mobileVw,
    desktopVw,
    fonts,
    colors,
    desktopBreakpoint,
} from '../styles'
import { useDictionaryQuery } from '../hooks'

const Article = ({
    location,
    data: { contentfulPageArticle, contentfulGlobalSite },
    pageContext: { shopenful },
}) => {
    const {
        thumbnailImage,
        title,
        seoTitle,
        seoDescription,
        author,
        date,
        body,
        relatedProducts,
        videoHeroblock,
    } = contentfulPageArticle

    const {
        articlePageDecorations,
        breadcrumbsArticlePages,
        facebookLink,
        instagramLink,
    } = contentfulGlobalSite

    const { width: windowWidth } = useWindowSize()

    const { recipeDiscoverProducts } = useDictionaryQuery()

    return (
        <StyledArticleSection>
            <SEO title={seoTitle} location={location} pageType='Article' description={seoDescription} />
            <HeroBlock
                data={{
                    title: [title],
                    background: ['full black'],
                    imagesSlider: [thumbnailImage],
                    video: videoHeroblock,
                    marginBottom: ['0', '0'],
                    breadcrumbs: breadcrumbsArticlePages,
                }}
            />
            <ArticleWrapper>
                <FlexWrapper>
                    <StyledSpan>{author}</StyledSpan>
                    <StyledDate>{date}</StyledDate>
                </FlexWrapper>
                {
                    body &&
                    <StyledRichText>
                        <RichText json={body} />
                    </StyledRichText>
                }
            </ArticleWrapper>
            <SocialWrapper>
                <Social
                    social={{
                        title: 'Partagez',
                        share: ['Instagram', 'Facebook'],
                        links: [`${instagramLink}`, `${facebookLink}`],
                    }}
                />
            </SocialWrapper>
            <Wrapper>
                {windowWidth < 1024 && (
                    <div className='mobileOnly'>
                        <Image
                            style={{ backgroundColor: `${colors.white}` }}
                            fluid={articlePageDecorations[0].image.fluid}
                            alt={articlePageDecorations[0].image.title}
                        />
                        <ProductSlider
                            title={recipeDiscoverProducts}
                            products={relatedProducts}
                            shopenful={shopenful}
                        />
                        <Image
                            fluid={articlePageDecorations[1].image.fluid}
                            alt={articlePageDecorations[1].image.title}
                        />
                    </div>
                )}
                {windowWidth >= 1024 && (
                    <div className='desktopOnly'>
                        <Image
                            style={{ backgroundColor: `${colors.white}` }}
                            fluid={articlePageDecorations[3].image.fluid}
                            alt={articlePageDecorations[3].image.title}
                        />
                        <Title>{recipeDiscoverProducts}</Title>
                        <ProductGallery>
                            {relatedProducts && relatedProducts.map((product, i) => (
                                <ProductWrapper resize={product.resizeImage} key={i}>
                                    <Productcard
                                        product={{
                                            ...product,
                                            thumbnailImage: product.images[0],
                                        }}
                                        shopenful={shopenful}
                                    />
                                </ProductWrapper>
                            ))}
                        </ProductGallery>
                        <Image
                            fluid={articlePageDecorations[2].image.fluid}
                            alt={articlePageDecorations[2].image.title}
                        />
                    </div>
                )}
            </Wrapper>
        </StyledArticleSection>
    )
}

const StyledArticleSection = styled.div`
  background: ${colors.black};
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    .breadcrumbs-wrapper {
      margin-bottom: ${desktopVw(58)};
    }

    h2 {
      font-size: ${desktopVw(35)};
      padding-right: ${desktopVw(20)};
      line-height: ${desktopVw(40)};
    }
  }
`

const ArticleWrapper = styled.div`
  color: ${colors.white};
  padding: ${mobileVw(30)} ${mobileVw(16)} 0;

  img {
    max-width: 100%;
  }

  h5 {
    text-align: center;
    font-family: ${fonts.mrs};
    color: ${colors.mailleGold};
    padding-bottom: ${mobileVw(10)};
    display: block;
  }

  a {
    text-decoration: underline;
  }

  h6 {
    font-family: ${fonts.mrs};
    font-size: ${mobileVw(12)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(50)} ${desktopVw(128)} 0;

    p {
      font-size: ${desktopVw(18)};
    }

    h5 {
      text-align: left;
      font-size: ${desktopVw(18)};
      line-height: ${desktopVw(24)};
      padding: ${desktopVw(44)} 0 ${desktopVw(7)};
    }

    h6 {
      font-size: ${desktopVw(16)};
    }

    img {
      padding-right: ${desktopVw(20)};
    }
  }
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: flex-start;
  }
`

const StyledDate = styled.h2`
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(22)};
  }
`

const StyledSpan = styled.span`
  color: ${colors.mailleGold};
  text-transform: uppercase;
  font-size: ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(1.4)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(22)};
    letter-spacing: ${desktopVw(2.2)};
    line-height: ${desktopVw(24)};
    padding-right: ${desktopVw(5)};
  }
`

const SocialWrapper = styled.div`
  padding-bottom: ${mobileVw(60)};

  section {
    margin-bottom: 0;
  }

  h2 {
    color: ${colors.mailleGold};
  }

  svg {
    fill: ${colors.white};
    path {
      fill: ${colors.white};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(60)} 0 ${desktopVw(100)};

    h2 {
      color: ${colors.white};
    }

    svg {
      fill: ${colors.mailleGold};
      path {
        fill: ${colors.mailleGold};
      }
    }
  }
`

const Wrapper = styled.div`
  background-color: ${colors.white};
  .gold-label {
    display: none;
  }
  h2 {
    color: ${colors.mailleGold};
  }
`

const Title = styled.h2`
  background-color: ${colors.white};
  font-family: ${fonts.splandor};
  color: ${colors.mailleGold};
  font-size: ${mobileVw(30)};
  padding: ${mobileVw(27)} 0 ${mobileVw(15)} ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(17)} 0 ${desktopVw(20)} ${desktopVw(128)};
    font-size: ${desktopVw(34)};
  }
`

const ProductGallery = styled.div`
  display: flex;
  background-color: ${colors.white};
  padding-bottom: ${mobileVw(80)};
  margin-bottom: ${mobileVw(-80)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(128)} ${desktopVw(350)};
  }
`

const ProductWrapper = styled.div`
  .imageContainer {
    img {
      object-fit: contain !important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-right: ${desktopVw(30)};
    width: ${desktopVw(200)};
  }
`

const StyledRichText = styled.div`
  h3 {
    text-transform: uppercase;
    font-size: ${mobileVw(26)};
    margin: 0 0 ${mobileVw(15)} 0;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(26)};
      margin: 0;
    }
  }
  .entry-card {
    display: inline-block;
    object-fit: cover;
    height: ${desktopVw(400)};
    width: 45%;
    @media (min-width: ${desktopBreakpoint}) {
      width: 50%;
      height: ${desktopVw(400)};
    }

    h4 {
      text-transform: uppercase;
      font-size: ${mobileVw(14)};
      margin: 0 0 ${mobileVw(15)} 0;

      @media (min-width: ${desktopBreakpoint}) {
        font-size: ${desktopVw(16)};
        margin: 0;
      }
    }

    img {
      @media (min-width: ${desktopBreakpoint}) {
        height: ${desktopVw(300)};
        margin: 0;
      }
      margin: 0 0 ${mobileVw(15)} 0;

      width: 100%;
      object-fit: cover;
    }
  }
`

export const article = graphql`
  query Article($id: String!, $node_locale: String!) {
    contentfulPageArticle(id: { eq: $id }) {
      videoHeroblock {
        name
        poster {
          fluid {
            src
          }
        }
        videoMov {
          file {
            url
          }
        }
        videoMp4 {
          file {
            url
          }
        }
      }
      id
      thumbnailImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      seoTitle
      seoDescription
      title
      author
      date(formatString: "DD-MM-YYYY")
      body {
        raw
        references {
            ...richtextReferences
        }
      }
      relatedProducts {
        shopifyProduct {
          id
          handle
          variants {
            edges {
              node {
                id
                title
              }
            }
          }
        }
        slug
        title
        productTags
        resizeImage
        thumbnailImage {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        images {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      facebookLink
      instagramLink
      breadcrumbsArticlePages {
        title
        link {
          ...linkTarget
        }
      }
      articlePageDecorations {
        name
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
    }
  }
`

export default Article
